* {
  font-family: "Gill Sans", sans-serif;
}

.navbar-style{
  font-size: large;
  position:fixed;
  top: 0;
}

.navbar-style .navbar-brand-style{
  font-size: x-large;
  font-weight: bold;
}

br{
  padding-top: 5%;
}



