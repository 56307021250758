.left-col{
    align-items: center;
    text-align: center;
    color: white;
}

i{
    padding: 2%;
}

.pic-me{
    width:70%;
    border: solid white;
  }

.parent{
    background-image: url(../../Images/Backgrounds/Canyons.jpg);
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    padding-left: 10%;
    padding-right: 10%;
}

a{
    color: white;
}

.home_h3{
text-align: center;
}

.home_ul{
font-size: 1.2em;
}
