.contact_parent{
    background-image: url(../../Images/Cali.jpeg);
    background-size: cover;
    /* background-position: center; */
    background-attachment: fixed;
    /* display: flex; */
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
}

.contact_h1{
    font-size: 4em;
    color: white;
    background-color: rgba(0,0,0,.8);
    padding: 1%;
}

.contact_list{
    font-size: 1.5em;
    /* text-align: left; */
    color: black;
}

.contact_h3{
    font-size: 3em;
    color: black;
    background-color: black;

}